<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">TLS</h1>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <!-- NOTE: same code appears in CheckWebsiteCertificate.vue ... TODO: refactor into a shared component -->
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Certificates</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-list dense v-if="list">
                            <v-list-item v-if="list.length === 0">No certificates yet.</v-list-item>
                            <v-list-item v-for="item in list" v-bind:key="item.id" @click="onClickItem(item)">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title>{{ item.subject_dn_text }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.domains.join(', ') }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="dialogShowCertificateDetail" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">Certificate</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="px-5" v-if="certificate">
                                <p>Issuer: {{ certificate.issuer_dn_text }}</p>
                                <p>Serial number: {{ certificate.serial }}</p>
                                <p>Subject: {{ certificate.subject_dn_text }}</p>
                                <p>Validity: {{ certificate.not_before_text }} to {{ certificate.not_after_text }}</p>
                                <p class="mb-0">Domains:</p>
                                <v-list dense>
                                    <v-list-item v-for="(domain, idx) in certificate.domains" :key="idx" class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ domain }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <template v-if="isPermitServiceAdmin">
                                <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="purple" class="white--text" @click="deployCertificate">Deploy certificate</v-btn>
                                </p>
                                </template>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="grey" @click="dialogShowCertificateDetail = false">
                                    <span>Close</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        list: null,
        dialogShowCertificateDetail: false,
        certificate: null, // for certificate detail dialog
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    watch: {
    },
    methods: {
        async loadTlsList() {
            try {
                this.$store.commit('loading', { loadTinydnsConfig: true });
                const response = await this.$client.main().serviceTlsCertificate.search();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadTinydnsConfig failed', err);
            } finally {
                this.$store.commit('loading', { loadTinydnsConfig: false });
            }
        },
        onClickItem(item) {
            this.certificate = item;
            this.dialogShowCertificateDetail = true;
        },
        async deployCertificate() {
            try {
                this.$store.commit('loading', { deployCertificate: true });
                const response = await this.$client.main().serviceTlsCertificate.deploy({ id: this.certificate.id });
                if (response?.status) {
                    console.log(`deployCertificate: status ${response.status}`);
                }
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Deployed certificate' });
            } catch (err) {
                console.error('deployCertificate failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deploy certificate' });
            } finally {
                this.$store.commit('loading', { deployCertificate: false });
            }
        },
    },
    mounted() {
        this.loadTlsList();
    },
};
</script>
